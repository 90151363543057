import React from "react"
import SiteLayout from "../components/SiteLayout"

export default function Home() {
  return (
    <SiteLayout>
      <h3>404 - Page not found.</h3>
    </SiteLayout>
  )
}
